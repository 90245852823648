import React from 'react'
import { SKILL_LEVEL } from '../../constants/skillConstants';
import SkillLevel from './SkillLevel';

const GameSkill = ({ minLevel, maxLevel, isStrict = false }) => {

  const minSkill = minLevel ? SKILL_LEVEL[minLevel] : null;
  const maxSkill = maxLevel ? SKILL_LEVEL[maxLevel] : null;

  // If minLevel and maxLevel are none
  if (!(minSkill || maxSkill)) return null;

  // If either of min level and max level exist but not both exist
  if ((minSkill && !maxSkill) || (maxSkill && !minSkill)) {
    return (
      <SkillLevel level={minLevel || maxLevel} />
    )
  };

  // If both min level and max Level exist
  return (
    <div className={`border relative bg-[#F1F3F2] rounded-lg w-48 h-full ml-2 overflow-hidden text-on_background`}>
      <div className={`w-full flex items-center h-full font-medium text-xs ${isStrict ? 'justify-between' : 'justify-center'}`}>
        <div className='truncate ml-1'>{minSkill.text} - {maxSkill.text}</div>
        {isStrict ? <div className='bg-[#3B4540] text-white text-[12px] leading-[1.5] font-medium right-0 flex items-center justify-center rounded-lg px-2 h-full'>MH</div> : null}
      </div>
    </div>
  );
};

export default GameSkill;
 